<template>
  <b-card>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewElLogo"
          :src="churchData.logo"
          :text="avatarText(churchData.name)"
          :variant="`light-primary`"
          size="100px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ churchData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputElLogo.click()"
        >
          <input
            ref="refInputElLogo"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>
    <!-- form -->
    <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-row>
        <!-- phone -->
        <b-col md="6">
            <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="churchData.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Sample Church"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </b-col>
        <b-col md="6">
                  <!-- type -->
                  <b-form-group
                    label="Type"
                    label-for="type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      vid="type"
                      rules="required"
                    >
                      <v-select
                        v-model="churchData.type"
                        :options="typeOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <!-- region -->
                  <b-form-group
                    label="Region"
                    label-for="region"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Region"
                      vid="region"
                      rules="required"
                    >
                      <v-select
                        v-model="churchData.region"
                        :options="regionOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
            </b-col>

           
            <b-col sm="12" md="6" cols="6">
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                    label="Code"
                    label-for="country"
                  >
                      <vue-country-code
                        class=" is-size-5"
                        @onSelect="onSelect"
                        v-model="churchData.country"
                        :enabledCountryCode="true"
                        :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                        >
                      </vue-country-code>
                    </b-form-group>
                  </b-col>
                  <b-col sm="8">
                    <b-form-group
                      label="Contact 1"
                      label-for="contact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Contact"
                        vid="contact"
                        rules="required"
                      >
                        <b-form-input
                          v-if="churchData.contact1"
                          id="contact"
                          v-model="churchData.contact1.tel"
                          name="contact"
                          :state="errors.length > 0 ? false:null"
                          placeholder="XXXXXXXXX"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                
              
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="District"
                  label-for="district"
                >
                    <b-form-input
                      id="district"
                      v-model="churchData.district"
                      name="district"
                      placeholder="District "
                    />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6" cols="6">
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                    label="Code"
                    label-for="country"
                  >
                      <vue-country-code
                        class=" is-size-5"
                        @onSelect="onSelect"
                        v-model="churchData.country"
                        :enabledCountryCode="true"
                        :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                        >
                      </vue-country-code>
                    </b-form-group>
                  </b-col>
                  <b-col sm="8">
                    <b-form-group
                      label="Contact 2"
                      label-for="contact2"
                    >
                        <b-form-input
                          v-if="churchData.contact2"
                          id="contact2"
                          v-model="churchData.contact2.tel"
                          name="contact"
                          placeholder="XXXXXXXXX"
                        />
                        <b-form-input
                          v-else
                          id="contact2"
                          v-model="churchData.contact2"
                          name="contact"
                          placeholder="XXXXXXXXX"
                        />
                    </b-form-group>
                  </b-col>
                </b-row>
                
              
              </b-col>

              <b-col md="6">
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                    <b-form-input
                      id="email"
                      v-model="churchData.email"
                      name="email"
                      placeholder="john@example.com"
                    />
                </b-form-group> 
              </b-col>

              <b-col md="6">
                <!-- email -->
                <b-form-group
                  label="Address"
                  label-for="address"
                >
                    <b-form-input
                      id="address"
                      v-model="churchData.address"
                      name="address"
                      placeholder="P.o.Box / GPS Address"
                    />
                </b-form-group> 
              </b-col>

              <b-col md="12">
                <!-- Address -->
                <b-form-group
                  label="Address"
                  label-for="address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                    vid="address"
                    rules="required"
                  >

                  <b-form-textarea
                    id="address"
                    v-model="churchData.addressName"
                    rows="4"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
                <!--  -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            type="submit"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BMedia, BAvatar
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, } from '@validations'
import { avatarText } from '@core/utils/filter'

import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BMedia,
    BAvatar,
    
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  props: {
    churchDataValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      typeOptions: [ 'Catholic', 'Methodist', 'Anglican', 'Presbyterian', 'Methodist/Presby', 'Lutherans', 'Seventh-Day Adventist', 'Pentecostal', 'Baptist', 'Evangelical Charismatics', 'Latter-day Saint', 'Other' ],
      regionOptions: ['Ashanti','Brong Ahafo','Central','Eastern','Greater Accra','Northern','Upper East','Upper West','Volta','Western','Savannah','Bono East','Oti','Ahafo','Western North','North East'],
    }
  },
  setup(props, { emit }) {

    const churchData = ref(null)
    churchData.value =  JSON.parse(JSON.stringify(props.churchDataValue))

    const onSelect = ({name, iso2, dialCode}) => {
      churchData.value.country = "+"+dialCode
      // console.log(name, iso2, dialCode);
    }

    const resetForm = () => {
      churchData.value = JSON.parse(JSON.stringify(props.churchDataValue))
    }

    const onSubmit = () => {
      // const profileData = JSON.parse(JSON.stringify(optionsLocal))
      // console.log('here')

      if (churchData.value.name) emit('update-church', churchData.value)

    }

    const refInputElLogo = ref(null)
    const previewElLogo = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputElLogo, base64 => {
      // eslint-disable-next-line no-param-reassign
      // console.log(base64)
      churchData.value.logo = base64
    })

    return {
      refInputElLogo,
      previewElLogo,
      inputImageRenderer,
      onSelect,
      resetForm,
      churchData,
      avatarText,
      onSubmit
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';


.vue-country-select  {
   width: 100%
}

.vue-country-select > .dropdown {
  padding: 0.6em !important;
}
</style>
