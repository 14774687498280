<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="account.profile"
        :general-data="account.profile"
        @update-profile="updateProfile"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password @update-password="updatePassword" />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab 
      v-if="$can('read', 'Analytics')">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="HomeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Church</span>
      </template>

      <account-setting-church
        v-if="churchData"
        :church-data-value="churchData"
        @update-church="updateChurch"
      />
    </b-tab>

    <!-- social links -->
    <b-tab 
      >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="MessageCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">SMS </span>
      </template>

      <account-setting-sms
        v-if="smsData"
        :sms-data="smsData"
        @add-sms-sender="addSmsSender"
      />
    </b-tab>

    <b-tab
    v-if="$can('read', 'Analytics')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Configurations </span>
      </template>

      <account-config
        v-if="churchData"
        :config-data-value="churchData"
        @update-config="updateConfig"
      />
    </b-tab>

    <!-- notification -->
    <!-- <b-tab 
      v-if="$can('read', 'Analytics')"> -->

      <!-- title -->
      <!-- <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      /> -->
    <!-- </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { onUnmounted, ref  } from '@vue/composition-api'
import store from '@/store'
import AccountConfig from './AccountConfig.vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingChurch from './AccountSettingChurch.vue'
import AccountSettingSms from './AccountSettingSms.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
const userData = JSON.parse(localStorage.getItem('userData'))
import useAccountProfile from './useAccountProfile'
import accountStoreModule from './accountStoreModule'
import axios from '@/libs/axios'

export default {
  components: {
    BTabs,
    BTab,
    AccountConfig,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingChurch,
    AccountSettingSms,
    AccountSettingNotification,
  },
  data() {
    return {
      account: {},
      options: {},
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
    
    
  },
  beforeMount() {
    this.account.profile = [userData].map(u => ({ id: u.id, avatar:  u.avatar ? u.avatar: null, fullName: u.fullName, userFullName: u.fullName, username: u.username, email: u.email, church: u.church }))[0]
  },
  setup(){
    //
    const { updateProfile, updateChurch, updatePassword, addSmsSender, updateConfig } = useAccountProfile()
    const churchData = ref(null)
    const smsData = ref(null)

    // fetch church
    axios.get(`/church/${userData.church}`).then(res => { 
      
      churchData.value = res.data; 
      // console.log(res.data); 
      smsData.value = { smsSender: res.data.smsSender, sms: res.data.sms } 
    })

    const SETTINGS_PROFILE_MODULE_NAME = 'settings-profile'
  
    // Register module
    if (!store.hasModule(SETTINGS_PROFILE_MODULE_NAME)) store.registerModule(SETTINGS_PROFILE_MODULE_NAME, accountStoreModule)
  
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_PROFILE_MODULE_NAME)) store.unregisterModule(SETTINGS_PROFILE_MODULE_NAME)
    })
    
    

    return {
      updateProfile,
      updateChurch,
      updatePassword,
      addSmsSender,
      updateConfig,
      churchData,
      smsData
    }
  }
}
</script>
