import axios from '@/libs/axios'
// '@/libs/axios'
const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: { church: {} },
  actions: {
    fetchProfiles({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${userData.church}`)
          .then(response => { 
            
            //
            commit('updateProfiles', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    fetchProfile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/profile/${userData.church}/${id}`)
          .then(response => { 

            //
            commit('updateProfile', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    updateChurch(ctx, { church }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/church/${userData.church}`, { ...church })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateConfig(ctx, { config }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/church/config/${userData.church}`, { ...config })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSmsSender(ctx, sender) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/church/sms/sender`, { sender, church: userData.church })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProfile(ctx, { prof }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/user/${prof.userFullName}`, { ...prof })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePassword(ctx, { pass }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/user/password/${userData.fullName}`, { ...pass, church: userData.church, id: userData.id  })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delProfile(ctx, { user }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/${userData.church}/${user.id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
