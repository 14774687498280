<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 100kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
      <!-- form -->
      <b-form @submit.prevent="handleSubmit(onSubmit)" class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Username"
              label-for="account-username"
            >
              <b-form-input
                v-model="optionsLocal.username"
                placeholder="Username"
                name="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Name"
              label-for="account-name"
            >
              <b-form-input
                v-model="optionsLocal.fullName"
                name="name"
                placeholder="Name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="optionsLocal.email"
                name="email"
                placeholder="Email"
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Church"
              label-for="account-church"
            >
              <b-form-input
                v-model="optionsLocal.church"
                name="church"
                placeholder="Church name"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <!-- alert -->
          <!-- <b-col
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col> -->
          <!--/ alert -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import accountStoreModule from './accountStoreModule'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ToastificationContent,
          
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props,{ emit }) {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const optionsLocal = ref(null)
    const profileFile = ref(null)

    
    optionsLocal.value = JSON.parse(JSON.stringify(props.generalData))

    const resetForm = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(props.generalData))
    }

    const SETTINGS_PROFILE_MODULE_NAME = 'settings-profile'
  
    // Register module
    if (!store.hasModule(SETTINGS_PROFILE_MODULE_NAME)) store.registerModule(SETTINGS_PROFILE_MODULE_NAME, accountStoreModule)
  
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_PROFILE_MODULE_NAME)) store.unregisterModule(SETTINGS_PROFILE_MODULE_NAME)
    })

    // Use toast
    const toast = useToast()

    const { inputImageRenderer } = useInputImageRenderer(refInputEl,
    base64 => {
      // eslint-disable-next-line no-param-reassign
      optionsLocal.value.avatar = base64
    })

    const onSubmit = () => {
      const profileData = JSON.parse(JSON.stringify(optionsLocal))

      if (props.generalData.church) emit('update-profile', profileData.value)

    }
    

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      toast,
      onSubmit,
      profileFile,
      optionsLocal,
      resetForm
    }
  },
}
</script>
