<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <!-- <feather-icon
              icon="MessageCircleIcon"
              size="18"
            /> -->
            <h4 class="mb-0 ml-75">
              SMS Sender & Balance
            </h4>
          </div>
        </b-col>

        <!-- sender -->
        <b-col md="3">
          <b-form-group
            label-for="sms-sender"
            label="Sender"
          >
            <b-form-input
              id="sms-sender"
              :disabled="localOptions.smsSender.approved"
              v-model="localOptions.smsSender.sender"
              placeholder="SMS Sender ID"
            />
          </b-form-group>
        </b-col>

        <b-col md="9">
          <b-form-group
            label-for="sms-status"
            label="Status"
          >
            <b-badge pill :variant="localOptions.smsSender.approved ? 'primary' : 'warning'" class=""> {{ localOptions.smsSender.approved ? 'Approved' : 'Pending' }} </b-badge>
            <b-button @click="onSubmit" v-if="!localOptions.smsSender.approved" variant="primary" class="ml-2"> Submit </b-button>
          </b-form-group>
          
        </b-col>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>

        <!-- twitter -->
        <b-col md="3">
          <b-form-group
            label-for="sms-total"
            label="Total"
          >
            <b-form-input
              id="sms-total"
              :disabled="true"
              :value="localOptions.sms.total"
              placeholder="Total"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label-for="sms-amount"
            label="Amount"
          >
            <b-form-input
              id="sms-amount"
              :disabled="true"
              :value="localOptions.sms.amount"
              placeholder="Amount"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label-for="sms-balance"
            label="Balance"
          >
            <b-form-input
              id="sms-balance"
              :disabled="true"
              :value="localOptions.sms.total - localOptions.sms.used"
              placeholder="Balance"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-button variant="primary" class="mt-2"> Purchase </b-button>
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1 ml-25"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Cancel
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BBadge
  },
  directives: {
    Ripple,
  },
  props: {
    smsData: {
      type: Object,
      default: () => {},
    },
  },
  // data() {
  //   return {
  //     localOptions: JSON.parse(JSON.stringify(this.smsData)),
  //   }
  // },
  // methods: {
  //   resetForm() {
  //     this.localOptions = JSON.parse(JSON.stringify(this.smsData))
  //   },
  // },
  setup(props, { emit }) {
    //
    const localOptions = ref(null)
    localOptions.value = JSON.parse(JSON.stringify(props.smsData))

    const resetForm = () => {
      localOptions.value = JSON.parse(JSON.stringify(props.smsData))
    }
    //
    const onSubmit = () => {
      if (localOptions.value.smsSender.sender && !localOptions.value.smsSender.status) emit('add-sms-sender', localOptions.value.smsSender)
    } 

    return {
      onSubmit,
      localOptions,
      resetForm
    }
  }
}
</script>
