<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="Old Password"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="formData.passwordValueOld"
                name="old-password"
                :type="formData.passwordFieldTypeOld"
                placeholder="Old Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="New Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="formData.newPasswordValue"
                :type="formData.passwordFieldTypeNew"
                name="new-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Retype New Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="formData.RetypePassword"
                :type="formData.passwordFieldTypeRetype"
                name="retype-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            type="submit"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import accountStoreModule from './accountStoreModule'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // passwordValueOld: '',
      // newPasswordValue: '',
      // RetypePassword: '',
      // passwordFieldTypeOld: 'password',
      // passwordFieldTypeNew: 'password',
      // passwordFieldTypeRetype: 'password',
    }
  },
  // computed: {
  //   passwordToggleIconOld() {
  //     return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  //   },
  //   passwordToggleIconNew() {
  //     return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  //   },
  //   passwordToggleIconRetype() {
  //     return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  //   },
  // },
  // methods: {
  //   togglePasswordOld() {
  //     this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
  //   },
  //   togglePasswordNew() {
  //     this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
  //   },
  //   togglePasswordRetype() {
  //     this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
  //   },
  // },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()
    //
    const blankForm = {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }

    const formData = ref(null)
    formData.value = JSON.parse(JSON.stringify(blankForm))

    const passwordToggleIconOld =  computed(() => {
      //
      return formData.value.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    })

    const passwordToggleIconNew =  computed(() => {
      //
      return formData.value.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    })

    const passwordToggleIconRetype =  computed(() => {
      //
      return formData.value.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    })


    const togglePasswordOld = () => {
      formData.value.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    }

    const togglePasswordNew = () => {
      formData.value.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    }

    const togglePasswordRetype = () => {
      formData.value.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    }


    const SETTINGS_PROFILE_MODULE_NAME = 'settings-profile'
  
    // Register module
    if (!store.hasModule(SETTINGS_PROFILE_MODULE_NAME)) store.registerModule(SETTINGS_PROFILE_MODULE_NAME, accountStoreModule)
  
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_PROFILE_MODULE_NAME)) store.unregisterModule(SETTINGS_PROFILE_MODULE_NAME)
    })

    const onSubmit = () => {

      // console.log(formData.value)
      if (!(formData.value.newPasswordValue || formData.value.passwordValueOld || formData.value.RetypePassword)) {
        return
      }

      if (formData.value.newPasswordValue !== formData.value.RetypePassword) {

        toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: Passwords don\'t match',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

      }

      else if (formData.value.newPasswordValue && formData.value.passwordValueOld)  emit('update-password', formData.value )

    }

    return {
      onSubmit,
      formData,
      passwordToggleIconOld,
      passwordToggleIconNew,
      passwordToggleIconRetype,
      togglePasswordOld,
      togglePasswordNew,
      togglePasswordRetype
    }
  }
}
</script>
