import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { computed  } from '@vue/composition-api'

export default function useAccountProfile() {
  // Use toast
  const toast = useToast()
  

  // ------------------------------------------------
  // updateProfile
  // ------------------------------------------------
  const updateProfile = eventData => {
    store.dispatch('settings-profile/updateProfile', { prof: eventData }).then(response => {
      //
      const profData = JSON.parse(JSON.stringify(getUserData()))

      profData.avatar = eventData.avatar
      profData.fullName = eventData.fullName
      profData.username = eventData.username
      profData.email = eventData.email
      // update data
      localStorage.setItem('userData', JSON.stringify(profData))
      //
      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {

        toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  // ------------------------------------------------
  // updatePassword
  // ------------------------------------------------
  const updatePassword = eventData => {
    store.dispatch('settings-profile/updatePassword', { pass: eventData }).then(response => {
      //
      setTimeout(()=>{
        // logout() 
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        ability.update(initialAbility)

        // Redirect to login page
        router.push({ name: 'auth-login' })

      }, 1500)
      //
      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {

        toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  // ------------------------------------------------
  // updateChurch
  // ------------------------------------------------
  const updateChurch = eventData => {
    store.dispatch('settings-profile/updateChurch', { church: eventData }).then(response => {
    // axios.patch(`/church/info/`,{ pass: eventData }).then(response => {
      //
      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {

        toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }


  // ------------------------------------------------
  // updateConfig
  // ------------------------------------------------
  const updateConfig = eventData => {
    store.dispatch('settings-profile/updateConfig', { config: eventData }).then(response => {
    // axios.patch(`/church/info/`,{ pass: eventData }).then(response => {
      //
      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {

        toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  

  const addSmsSender = ({ sender }) => {
    store.dispatch('settings-profile/addSmsSender', sender ).then(response => {
    // axios.patch(`/church/info/`,{ pass: eventData }).then(response => {
    setTimeout(()=> {
      window.location.reload()
    }, 1500)

      //
      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {

        toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  return {
    // onSubmit,
    updateProfile,
    updatePassword,
    updateChurch,
    addSmsSender,
    updateConfig
  }
}
