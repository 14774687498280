<template>
    <b-card>
      
      <!-- form -->
      <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="4">
            <b-form-group
                    label="Prefix"
                    label-for="Prefix"
                >
                <b-form-input
                    id="prefix"
                    v-model="configData.prefix"
                    name="prefix"
                    placeholder="Characters before ID"
                  />
            </b-form-group>
          </b-col>
          <b-col md="4">
                    <!-- type -->
                    <b-form-group
                      label="ID Generation Mechanism"
                      label-for="type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Type"
                        vid="type"
                        rules="required"
                      >
                        <v-select
                          v-model="configData.IdGenerationMechanism"
                          :options="IdGenerationTypes"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
            </b-col>
            <b-col cols="4">
                <!-- region -->
                <b-form-group
                    label="Suffix"
                    label-for="Suffix"
                >
                        <b-form-input
                            id="suffix"
                            v-model="configData.suffix"
                            name="suffix"
                            placeholder="Characters after ID"
                          />
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <!-- region -->
                <b-form-group
                    label="Digit Sequence - 000 / 0000 / 00000"
                    label-for="DigitSequence"
                >
                    
                    <validation-provider
                        #default="{ errors }"
                        name="DigitSequence"
                        vid="DigitSequence"
                        rules="required"
                      >
                        <b-form-input
                            id="DigitSequence"
                            v-model="configData.digitSequence"
                            name="DigitSequence"
                            placeholder="Zeros for the ID Generation"
                            :state="errors.length > 0 ? false:null"
                          />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col md="4">
                    <!-- type -->
                    <b-form-group
                      label="Base Currency"
                      label-for="Base Currency"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Base Currency"
                        vid="Base Currency"
                        rules="required"
                      >
                        <v-select
                          v-model="configData.baseCurrency"
                          :options="currencyOptions"
                          :state="errors.length > 0 ? false:null"
                          label="name"
                        >
                            <template #option="{ name, sign }">
                                <span class="ml-50 align-middle"> {{ name }} - {{ sign }} </span>
                            </template>

                            <template #selected-option="{  name, sign }">
                                <span class="ml-50 align-middle"> {{ name }} - {{ sign }} </span>
                            </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
            </b-col>
            
            <b-col md="4">
                <b-form-group
                        label="Church Code"
                        label-for="code"
                    >
                    <b-form-input
                        id="code"
                        v-model="configData.churchCode"
                        name="code"
                        placeholder="Church Code"
                    />
                </b-form-group>
            </b-col>
             
            <b-col
                cols="12"
                class="mt-2 mb-1"
              >
                <h5 class="mb-0">
                  Currencies <b-badge variant="primary" @click="addCurrency">Add</b-badge>
                </h5>
                <small class="text-muted">Enter currencies and their rates </small>
            </b-col>
                
            <b-col sm="12">
                <b-row v-for="(currency, index) in configData.currencies" :key="index">

                  <b-col md="6">
                      <b-form-group
                          label="Currency" 
                          label-for="currency"
                      >
                          <v-select
                            v-model="currency.currency"
                            :options="currencyOptions.filter(c=>c.name !== configData.baseCurrency.name) "
                            label="name"
                          >
                          <template #option="{ name, sign }">
                                <span class="ml-50 align-middle"> {{ name }} - {{ sign }} </span>
                            </template>

                            <template #selected-option="{  name, sign }">
                                <span class="ml-50 align-middle"> {{ name }} - {{ sign }} </span>
                            </template>
                        </v-select>
                      </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                        label="Rate "
                        label-for="Rate"
                        >

                            <b-form-input
                            type="number"
                            v-model="currency.rate"
                            step="0.01"
                            placeholder="Rate"
                            />
                            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                        </b-form-group>
                  </b-col>

                  <b-col md="1">
                      <b-form-group
                      label="Add"
                      label-for=""
                      >
                      <b-button
                        variant="primary"
                        @click="addCurrency"
                        >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                          class="mr-0 mr-sm-10"
                        />
                      </b-button>
                      </b-form-group>
                  </b-col>
                  <b-col md="1">
                      <b-form-group
                      label="Remove"
                      label-for=""
                      >
                      <b-button
                        variant="danger"
                        @click="delCurrency(index)"
                        >
                        <feather-icon
                          icon="XIcon"
                          size="16"
                        />
                      </b-button>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                    cols="12"
                    class="mt-2 mb-1"
                >
                    <h5 class="mb-0">
                    Others
                    </h5>
                    <small class="text-muted">Other configurations </small>
                </b-col>

                <b-col>
                    <b-row>
                        <b-col
                            cols="4"
                            class="mt-1 mb-2"
                        >
                            <b-form-checkbox
                            id="accountSwitch4"
                            v-model="configData.isApprovalReceipts"
                            name="check-button"
                            switch
                            inline
                            >
                            <span
                                v-b-tooltip.hover.top="'With this enabled all Receipts created will be pending until approved'"
                            >Enable Approval feature for Receipts</span>
                            </b-form-checkbox>
                        </b-col>

                        <b-col
                            cols="4"
                            class="mt-1 mb-2"
                        >
                            <b-form-checkbox
                            id="accountSwitch5"
                            v-model="configData.isApprovalPayments"
                            name="check-button"
                            switch
                            inline
                            >
                            <span
                                v-b-tooltip.hover.top="'With this enabled all Payments created will be pending until approved'"
                            >Enable Approval feature for Payments</span>
                            </b-form-checkbox>
                        </b-col>

                        <b-col
                            cols="4"
                            class="mt-1 mb-2"
                        >
                            <b-form-checkbox
                            id="accountSwitch6"
                            v-model="configData.isHeadquarters"
                            name="check-button"
                            switch
                            inline
                            >
                            <span
                                v-b-tooltip.hover.top="'Enable for church with Branches and a Headquarters'"
                            >Branches and Headquarters</span>
                            </b-form-checkbox>
                        </b-col>
                        
                    </b-row>
                </b-col>

                
                <b-col
                    v-if="configData.isHeadquarters"
                    cols="12"
                    class="mt-2 mb-1"
                >
                    <h5 class="mb-0">
                    Branches <b-badge variant="primary" @click="addBranch">Add</b-badge>
                    </h5>
                    <small class="text-muted">Add Branches </small>
                </b-col>
                    
                <b-col v-if="configData.isHeadquarters" sm="12">
                    <b-row v-for="(branch, index) in configData.branches" :key="index">

                    <b-col md="4">
                        <b-form-group
                            label="Branch" 
                            label-for="branch"
                        >
                        <validation-provider
                            #default="{ errors }"
                            name="Branch"
                            vid="Branch"
                            rules="required"
                        >
                            <b-form-input
                                v-model="branch.church"
                                label="name"
                                placeholder="Branch Name"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group
                            label="Branch Code" 
                            label-for="code"
                        >
                        <b-form-input
                                v-model="branch.churchCode"
                                placeholder="Branch Code"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-row>
                            <b-col sm="4">
                                <b-form-group
                                label="Code"
                                label-for="country"
                            >
                                <vue-country-code
                                    class=" is-size-5"
                                    @onSelect="onSelect"
                                    v-model="branch.contact.countryCode"
                                    :enabledCountryCode="true"
                                    :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                    >
                                </vue-country-code>
                                </b-form-group>
                            </b-col>
                            <b-col sm="8">
                                <b-form-group
                                label="Contact"
                                label-for="contact"
                                >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Contact"
                                    vid="contact"
                                    rules="required"
                                >
                                    <b-form-input
                                    id="contact"
                                    v-model="branch.contact.tel"
                                    name="contact"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="XXXXXXXXX"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col md="1">
                        <b-form-group
                        label="Add"
                        label-for=""
                        >
                        <b-button
                            variant="primary"
                            @click="addBranch"
                            >
                            <feather-icon
                            icon="PlusIcon"
                            size="16"
                            class="mr-0 mr-sm-10"
                            />
                        </b-button>
                        </b-form-group>
                    </b-col>
                    <b-col md="1">
                        <b-form-group
                        label="Remove"
                        label-for=""
                        >
                        <b-button
                            variant="danger"
                            @click="delBranch(index)"
                            >
                            <feather-icon
                            icon="XIcon"
                            size="16"
                            />
                        </b-button>
                        </b-form-group>
                        </b-col>

                    </b-row>
                </b-col>
                  <!--  -->
            <b-col cols="12">
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-1 mr-1"
                    type="submit"
                    >
                    Save changes
                    </b-button>
                    <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    class="mt-1"
                    variant="outline-secondary"
                    @click.prevent="resetForm"
                    >
                    Reset
                    </b-button>
            </b-col>
        </b-row>
      </b-form>
      </validation-observer>
    </b-card>
  </template>
  
  <script>
  import {
    BButton, BForm, BFormGroup, BFormCheckbox, BFormInput, BRow, BCol, BCard, BFormTextarea, BMedia, BAvatar, BBadge, VBTooltip
  } from 'bootstrap-vue'
  import { ref } from '@vue/composition-api'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  import Ripple from 'vue-ripple-directive'
  import Cleave from 'vue-cleave-component'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, } from '@validations'
  import { avatarText } from '@core/utils/filter'
  
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  
  // eslint-disable-next-line import/no-extraneous-dependencies
  import 'cleave.js/dist/addons/cleave-phone.us'
  
  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormCheckbox,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BFormTextarea,
      vSelect,
      flatPickr,
      Cleave,
      BMedia,
      BAvatar,
      
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BBadge
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip
    },
    props: {
      configDataValue: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        required,
        typeOptions: [ 'Catholic', 'Methodist', 'Anglican', 'Presbyterian', 'Methodist/Presby', 'Lutherans', 'Seventh-Day Adventist', 'Pentecostal', 'Baptist', 'Evangelical Charismatics', 'Latter-day Saint', 'Other' ],
        regionOptions: ['Ashanti','Brong Ahafo','Central','Eastern','Greater Accra','Northern','Upper East','Upper West','Volta','Western','Savannah','Bono East','Oti','Ahafo','Western North','North East'],
        IdGenerationTypes: [ 'UUID', 'Sequence', 'MaleFemaleSequence', 'Manual' ],
        currencyOptions: [
            {
                name: "Cedi",
                sign: "₵"
            },
            {
                name: "Naira",
                sign: "₦"
            },
            {
                name: "Dollar",
                sign: "$"
            },
            {
                name: "Pound",
                sign: "£"
            },
            {
                name: "Euro",
                sign: "€"
            }
        ]
      }
    },
    setup(props, { emit }) {
  
      const configData = ref(null)
      configData.value =  JSON.parse(JSON.stringify(props.configDataValue))
  
      const onSelect = ({name, iso2, dialCode}) => {
        configData.value.country = "+"+dialCode
        // console.log(name, iso2, dialCode);
      }
  
      const resetForm = () => {
        configData.value = JSON.parse(JSON.stringify(props.configDataValue))
      }
  
      const onSubmit = () => {
        // const profileData = JSON.parse(JSON.stringify(optionsLocal))
        // console.log('here')
  
        if (configData.value.name) emit('update-config', configData.value)
  
      }
  
      const refInputElLogo = ref(null)
      const previewElLogo = ref(null)
  
    //   const { inputImageRenderer } = useInputImageRenderer(refInputElLogo, base64 => {
        // eslint-disable-next-line no-param-reassign
        // console.log(base64)
        // configData.value.logo = base64
    //   })

    const addCurrency = () => {
      let currency = {
        currency: '',
        rate: '',
      }

      if (typeof(configData.value.currencies) == "undefined") {

        configData.value["currencies"] = []
        configData.value.currencies.push(currency)

      } else {
        configData.value.currencies.push(currency)
      }

    }

    const delCurrency = (key) => {
      configData.value.currencies.splice(key, 1)
    }

    const addBranch = () => {
      let church = {
        church: '',
        churchCode: '',
        contact: {
            countryCode: '',
            tel: ''
        }
      }

      if (typeof(configData.value.branches) == "undefined") {

        configData.value["branches"] = []
        configData.value.branches.push(church)

      } else {
        configData.value.branches.push(church)
      }

    }

    const delBranch = (key) => {
      configData.value.branches.splice(key, 1)
    }
  
      return {
        // refInputElLogo,
        // previewElLogo,
        // inputImageRenderer,
        onSelect,
        resetForm,
        configData,
        avatarText,
        onSubmit,
        addCurrency,
        delCurrency,
        addBranch,
        delBranch
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  
  .vue-country-select  {
     width: 100%
  }
  
  .vue-country-select > .dropdown {
    padding: 0.6em !important;
  }
  </style>
  